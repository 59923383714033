import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { AppContext } from "./context/context"
import Footer from "./footer/Footer"
import Header from "./main-header/Header"
import SecondaryHeader from "./secondary-header/SecondaryHeader"
import SideNav from "./side-nav/SideNav"
import LiveGoogleRating from "./google-rating-banner/LiveGoogleRating"
import { setTrackingCookies } from "../assets/js/helper"
import InnerHTML from "dangerously-set-html-content"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"

const Layout = ({ children, location }) => {
  const { height } = useContext(AppContext)

  useEffect(() => {
    setTrackingCookies()
  }, [])

  const data = useStaticQuery(getData)
  const { kontentItemSettings, nodeEnvs } = data
  const { whereWeServiceActive, whereWeServiceSlug, googleID } = nodeEnvs
  const {
    company_name,
    primary_logo,
    alternative_logo,
    footer_logo,
    corporate_head_office,
    primary_color,
    primary_alternate_color,
    secondary_color,
    secondary_alternate_color,
    accent_color,
    accent_alternate_color,
    link_color,
    enable_buy_online,
    pestroutes_reviews,
    pestroutes_portal,
    buy_online_page,
    header_navigation,
    side_navigation,
    footer_navigation,
    social_media,
    custom_cta_text,
    google_rating_banner,
    additional_offices,
    custom_css,
    code,
    secondary_header_navigation,
  } = kontentItemSettings.elements

  const { phone, tracking_number } = corporate_head_office.value[0].elements

  const buyOnlineLink = buy_online_page.value[0].elements.slug.value

  return (
    <>
      <Helmet
        meta={
          googleID && [{ name: "google-site-verification", content: googleID }]
        }
      >
        <style>{`:root{--primary: ${primary_color.value}; --primaryAlt:${primary_alternate_color.value}; --secondary: ${secondary_color.value};--secondaryAlt: ${secondary_alternate_color.value}; --accent: ${accent_color.value}; --accentAlt:${accent_alternate_color.value}; --link:${link_color.value}}`}</style>
        {custom_css.value.length > 0 && (
          <link rel="stylesheet" href={custom_css.value[0].url} />
        )}
      </Helmet>
      <SecondaryHeader
        phone={tracking_number.value}
        showReviews={pestroutes_reviews.value[0]}
        showPortal={pestroutes_portal.value[0]}
        navItems={secondary_header_navigation}
      />
      {height > 40 ? (
        <Header
          logo={primary_logo.value}
          sticky={true}
          navItems={header_navigation.value}
          whereWeServiceActive={whereWeServiceActive}
          whereWeServiceSlug={whereWeServiceSlug}
        />
      ) : (
        <Header
          logo={primary_logo.value}
          navItems={header_navigation.value}
          whereWeServiceActive={whereWeServiceActive}
          whereWeServiceSlug={whereWeServiceSlug}
        />
      )}
      <SideNav
        sideNavItems={side_navigation.value}
        logo={alternative_logo.value}
        prPortal={pestroutes_portal}
        prReviews={pestroutes_reviews}
        enableBuyOnline={enable_buy_online}
        buyOnlineLink={buyOnlineLink}
        location={location}
        phone={phone.value}
      />
      <div className="wrapper">
        <div className="content">
          <main className="page-content">{children}</main>
        </div>
        {location !== "/login" && google_rating_banner?.value[0] && (
          <LiveGoogleRating
            url={google_rating_banner.value[0].elements.google_url.value}
          />
        )}
        <Footer
          logo={footer_logo}
          companyName={company_name.value}
          customCTA={custom_cta_text.value}
          social={social_media}
          location={corporate_head_office}
          trackingPhone={tracking_number.value}
          footerNavItems={footer_navigation.value}
          additionalOffices={additional_offices}
        />
        {code?.value !== "" && (
          <InnerHTML contentEditable="false" html={code.value}></InnerHTML>
        )}
      </div>
    </>
  )
}

const getData = graphql`
  query getSettings {
    nodeEnvs {
      whereWeServiceActive
      whereWeServiceSlug
      recapKey
      googleID
    }
    kontentItemSettings {
      elements {
        primary_logo {
          value {
            fluid(maxWidth: 200) {
              ...KontentAssetFluid_withWebp
              base64
            }
            description
          }
        }
        alternative_logo {
          value {
            fluid(maxWidth: 200) {
              ...KontentAssetFluid_withWebp
              base64
            }
            description
          }
        }
        footer_logo {
          value {
            fluid(maxWidth: 200) {
              ...KontentAssetFluid_withWebp
              base64
            }
            description
          }
        }
        enable_buy_online {
          value {
            codename
          }
        }
        pestroutes_reviews {
          value {
            codename
          }
        }
        pestroutes_portal {
          value {
            codename
          }
        }
        primary_color {
          value
        }
        primary_alternate_color {
          value
        }
        secondary_color {
          value
        }
        secondary_alternate_color {
          value
        }
        accent_color {
          value
        }
        accent_alternate_color {
          value
        }
        link_color {
          value
        }
        corporate_head_office {
          value {
            ... on kontent_item_office {
              id
              elements {
                name {
                  value
                }
                address1: address_line_1 {
                  value
                }
                address2: address_line_2 {
                  value
                }
                county {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                phone: phone_number {
                  value
                }
                tracking_number {
                  value
                }
                organization_membership {
                  value {
                    name
                  }
                }
                short_description {
                  value
                }
              }
            }
          }
        }
        additional_offices {
          value {
            ... on kontent_item_office {
              id
              elements {
                name {
                  value
                }
                address1: address_line_1 {
                  value
                }
                address2: address_line_2 {
                  value
                }
                county {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                phone: phone_number {
                  value
                }
                tracking_number {
                  value
                }
                organization_membership {
                  value {
                    name
                  }
                }
                short_description {
                  value
                }
              }
            }
          }
        }
        buy_online_page {
          value {
            ... on kontent_item_page {
              id
              elements {
                slug {
                  value
                }
              }
            }
          }
        }
        enable_alert_banner {
          value {
            codename
          }
        }
        header_navigation {
          value {
            ... on kontent_item_navigation_item {
              id
              elements {
                link_text {
                  value
                }
                page {
                  value {
                    ... on kontent_item_page {
                      id
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                    ... on kontent_item_service_area {
                      id
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                child_page {
                  value {
                    ... on kontent_item_navigation_item {
                      id
                      elements {
                        link_text {
                          value
                        }
                        page {
                          value {
                            ... on kontent_item_page {
                              id
                              elements {
                                slug {
                                  value
                                }
                              }
                            }
                            ... on kontent_item_service_area {
                              id
                              elements {
                                slug {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        footer_navigation {
          value {
            ... on kontent_item_navigation_item {
              id
              elements {
                child_page {
                  value {
                    ... on kontent_item_image_navigation {
                      id
                    }
                  }
                }
                link_text {
                  value
                }
                external_link {
                  value
                }
                page {
                  value {
                    ... on kontent_item_page {
                      elements {
                        slug {
                          value
                        }
                      }
                      id
                    }
                  }
                }
              }
            }
          }
        }
        company_email {
          value
        }
        company_name {
          value
        }
        custom_cta_text {
          value
        }
        side_navigation {
          value {
            ... on kontent_item_navigation_item {
              id
              elements {
                page {
                  value {
                    ... on kontent_item_page {
                      id
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                    ... on kontent_item_service_area {
                      id
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                child_page {
                  value {
                    ... on kontent_item_navigation_item {
                      id
                      elements {
                        link_text {
                          value
                        }
                        external_link {
                          value
                        }
                        page {
                          value {
                            ... on kontent_item_page {
                              id
                              elements {
                                slug {
                                  value
                                }
                              }
                            }
                            ... on kontent_item_service_area {
                              id
                              elements {
                                slug {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                link_text {
                  value
                }
                external_link {
                  value
                }
              }
            }
          }
        }
        site_name {
          value
        }
        google_rating_banner {
          value {
            ... on kontent_item_google_rating_banner {
              id
              elements {
                google_url {
                  value
                }
              }
            }
          }
        }
        social_media {
          value {
            ... on kontent_item_social_media_link {
              id
              elements {
                link {
                  value
                }
                social_media_outlet {
                  value {
                    codename
                  }
                }
              }
            }
          }
        }
        custom_css {
          value {
            url
          }
        }
        code {
          value
        }
        secondary_header_navigation {
          value {
            ... on kontent_item_navigation_item {
              id
              elements {
                external_link {
                  value
                }
                link_text {
                  value
                }
                page {
                  value {
                    ... on kontent_item_page {
                      id
                      elements {
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
