import React, { useContext } from "react"
import { AppContext } from "../context/context"
import { FaChevronRight } from "react-icons/fa"
import { MdPermPhoneMsg } from "react-icons/md"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import * as styles from "./form-stub.module.scss"

const FormStub = ({ content, phoneNumber, currentPage }) => {
  const { size } = useContext(AppContext)
  const { heading, subheading, button_text } = content.elements

  const getTablet = () => {
    if (size >= 1024) {
      return (
        <a href={`tel: ${phoneNumber}`} className={styles.callButton}>
          <p className={styles.subheading}>{subheading.value}</p>
          <div className={styles.phone}>
            <MdPermPhoneMsg />
          </div>
          <span>{phoneNumber}</span>
        </a>
      )
    }
  }
  return (
    <div className={styles.bannerCta}>
      <div className={`container ${styles.ctaContainer}`}>
        <h3 className={styles.heading}>{heading.value}</h3>
        <div className={styles.ctaFormContainer}>
          <AnchorLink
            to={`${currentPage}#form-banner`}
            className="accent-button"
          >
            <span className={styles.buttonContent}>
              {button_text.value}
              <FaChevronRight />
            </span>
          </AnchorLink>
        </div>
        {getTablet()}
      </div>
    </div>
  )
}

export default FormStub
