import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { AppContext } from "../context/context"
import * as styles from "./secondary-header.module.scss"

const SecondaryHeader = ({
  phone,
  showReviews,
  showPortal,
  navItems,
  location,
}) => {
  const { size } = React.useContext(AppContext)

  if (size < 550) {
    return (
      <div className={styles.secondaryHeader}>
        {showPortal.codename === "yes" ? (
          <span className={styles.portalLinkWrapper}>
            <a
              href="/login"
              className={`${styles.portalLink} ${
                location === "/login" && styles.current
              }`}
            >
              Customer Login
            </a>
          </span>
        ) : (
          ""
        )}
        <a
          href={`tel: ${phone}`}
          className={styles.secondaryHeaderInner}
          id="secondary-header-phone-link"
        >
          <span className={styles.phoneLink}>{phone}</span>
        </a>
      </div>
    )
  } else {
    return (
      <div className={styles.secondaryHeader}>
        <div className={`container ${styles.secondaryHeaderInner}`}>
          <div className={styles.portalLinks}>
            <span className={styles.portalLinkWrapper}>
              <Link
                to="/about-us"
                className={styles.portalLink}
                activeClassName={styles.current}
              >
                About Us
              </Link>
            </span>

            <span className={styles.portalLinkWrapper}>
              <Link
                to="/blog"
                className={styles.portalLink}
                activeClassName={styles.current}
              >
                Blog
              </Link>
            </span>
            <span className={styles.portalLinkWrapper}>
              <Link
                to="/contact-us"
                className={styles.portalLink}
                activeClassName={styles.current}
              >
                Contact Us
              </Link>
            </span>
            {showPortal.codename === "yes" ? (
              <span className={styles.portalLinkWrapper}>
                <a
                  href="/login"
                  className={`${styles.portalLink} ${
                    location === "/login" && styles.current
                  }`}
                >
                  Client Login
                </a>
              </span>
            ) : (
              ""
            )}

            {showReviews.codename === "yes" ? (
              <span className={styles.portalLinkWrapper}>
                <Link
                  to="/reviews"
                  className={styles.portalLink}
                  activeClassName={styles.current}
                >
                  Customer Reviews
                </Link>
              </span>
            ) : (
              ""
            )}
            {navItems &&
              navItems.value.map(navItem => {
                return (
                  <span className={styles.portalLinkWrapper}>
                    <Link
                      to={
                        navItem.elements.external_link.value === ""
                          ? `/${navItem.elements.page.value[0].elements.slug.value}`
                          : `/${navItem.elements.external_link.value}`
                      }
                      className={styles.portalLink}
                    >
                      {navItem.elements.link_text.value}
                    </Link>
                  </span>
                )
              })}
          </div>
          <a
            href={`tel: ${phone}`}
            className={styles.secondaryHeaderInner}
            id="secondary-header-phone-link"
          >
            <span className={styles.phoneLink}>{phone}</span>
          </a>
        </div>
      </div>
    )
  }
}

SecondaryHeader.propTypes = {
  phone: PropTypes.string,
}

SecondaryHeader.defaultProps = {
  phone: "(888) 888-8888",
}

export default SecondaryHeader
